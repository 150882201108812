@font-face {
  font-family: 'Fira Sans';
  src: url('./assets/fonts/FiraSans-Regular.ttf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('./assets/fonts/FiraSans-Medium.ttf') format('opentype');
  font-weight: 500;
}
@font-face {
  font-family: 'Fira Sans Condensed';
  src: url('./assets/fonts/FiraSansCondensed-Regular.ttf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'Fira Code';
  src: url('./assets/fonts/FiraCode-Medium.ttf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'Fira Code';
  src: url('./assets/fonts/FiraCode-Light.ttf') format('opentype');
  font-weight: 300;
}
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
/* Add more @font-face rules for each font file in the folder */
body {
  margin: 0;
  font-family: 'Avenir Next LT Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  /* scroll-behavior: smooth; */
}
