.activityTitle{
    color: #02111B;
    font-family: "Fira Code";
    font-size: 56px;
    font-style: normal;
    font-weight: 300;
    line-height: 135%; /* 75.6px */
    letter-spacing: -1.12px;
}
.activityDescription{
    color: #50555B;
    font-family: "Fira Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
}
.approachDescription{
    color: #02111B;
    font-family: "Fira Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 33px */
    letter-spacing: -0.44px;
}