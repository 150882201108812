
.Container{
  display: flex;
  flex-direction: column;
  padding: 0 88px;
  
}

/* Default styles (for screens larger than 1440px) */
.main-logo {
  width: 240px;
  height: 112.444px;
  flex-shrink: 0;
  object-fit: contain;
}
.navbar img,
.contact-logo {
  transition: all 0.5s;
}

.main-logo.small {
  width: 120px;
  height: 56px;
}

.contact-logo {
  width: 48px;
  height: 48px;
}

.contact-logo.small{
  width: 32px;
  height: 32px;
}

.hamburger-logo {
  width: 180px;
  height: 80.444px;
}

.nav-link {
  color: #02111B;
  font-family: "Fira Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 26px */
  letter-spacing: -0.4px;
  padding: 8px 12px;
  border-bottom: 2px solid transparent;
}
.enquiry-button{
  font-family: "Fira Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 26px */
  letter-spacing: -0.4px;
  padding: 8px 12px;
  background-color: #F2623B;
  color:#FFF;
}
.nav-link.button {
  background-color: #F2623B;
  color:#FFF;
}
.nav-link:last-child{
  color: #1D3D7C
}

.nav-link.active {
  font-weight: 500;
  color: #F2623B;
  border-bottom: 2px solid #F2623B;
}
.nav-link:hover{
  background-color: #F2623B;
  color: #FFF;
}

/* Forms */
.form-container{
  display: inline-flex; 
  padding: 32px 56px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 10px;
  background: linear-gradient(180deg, #ECF3FB 0%, #B7D4EF 173.4%);
  box-shadow: 0px 1.5px 1px 0px #FFF;
  /* backdrop-filter: blur(1px); */
  z-index: 30;
  position: relative;
}

.form-sub-container{
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.form-heading{
  color: #02111B;
font-size: 48px;
font-weight: 400;
line-height: 110%; /* 52.8px */
letter-spacing: -1.92px;
font-family: "Fira Sans";
}
.form-input{
padding: 21px 26px 21px 24px;
align-items: center;
flex-shrink: 0;
width: 100%;
color: #8692A6;

font-family: "Fira Sans";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.first\:rounded-t-lg:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

/* Responsive styles */

/* For 1440px screen width and below */
@media screen and (max-width: 1440px) {
  .main-logo {
    width: 187.827px;
    height: 88px;
  }
  
}

/* For 768px screen width and below */
@media screen and (max-width: 768px) {
  .main-logo {
    width: 94px;
    height: 88px;
    flex-shrink: 0;
  }
  .main-logo.small {
    width: 70px;
    height: 44px;
  }
   .form-container{
   padding: 40px;
}
  .contact-logo {
    width: 45px;
    height: 45px;
  }
  .contact-logo.small{
    width: 32px;
    height: 32px;
  }

  .hamburger-logo {
    width: 80px;
    height: 60.444px;
  }
  .form-input{
padding: 21px 26px 21px 24px;

}
}

/* For 375px screen width and below */
@media screen and (max-width: 375px) {
  .main-logo {
    width: 90.818px;
    height: 56px;
    flex-shrink: 0;
  }
  .main-logo.small {
    width: 45px;
    height: 30px;
  }

  .contact-logo {
    width: 32px;
    height: 32px;
  }

  .contact-logo.small{
    width: 24px;
    height: 24px;
  }

  .product-label-text{
    font-size: 16px;
    letter-spacing: -0.32px;
  }
  
  .form-container{
   padding: 40px 24px;
}


  .hamburger-logo {
    width: 36.75px;
    height: 24.15px;
  }



.form-heading{
font-size: 32px;
letter-spacing: -1.28px;
}
.form-input{
padding: 16px 26px 16px 24px;

}
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}
.divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: 0px;
    border-bottom-width: 0px;
}