/* Modal styles */
.modal {
    display: flex; /* Hide the modal by default */
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
}

.modal-content {
    background-color: #fefefe;
    /* padding: 20px; */
    border: 1px solid #888;
    width: 80%;
    height: 80dvh;
}

.modal-close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.modal-close:hover,
.modal-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.slick-prev::before,.slick-next::before {
    color: #000 !important;
}